import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const FadeOut = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
`;

const Wrapper = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem',
  backgroundColor: '#E2DAD2',
  borderRadius: '0.5rem',
  fontSize: '0.8rem',
  zIndex: 3,

  animationDelay: '1s',
  animationFillMode: 'both',
  animationIterationCount: '1',
  animationName: FadeOut,
  animationTimingFunction: 'ease-in',
  animationDuration: '0.2s',
});

type Props = {
  onDismiss: () => void;
};

export function Notification({ onDismiss } : Props) {
  return (
    <Wrapper onAnimationEnd={onDismiss}>
      복사되었습니다.
    </Wrapper>
  );
}

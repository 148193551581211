import styled from '@emotion/styled';
import React, { useState } from 'react';
import copyIcon from '~/assets/copy_icon.svg';
import { FontColor } from '~/constants';
import { SubText, SubTitle } from './common';
import { Notification } from '../Common/Notification';

const Wrapper = styled.div({
  padding: '1.5rem 1rem 1rem',
  backgroundColor: '#F9F7F5',
});

const Address = styled.p({
  fontWeight: 'bold',
  color: FontColor.Normal,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
});

const Copy = styled.button({
  background: 'none',
  paddingTop: '0.3rem',
  marginLeft: '0.2rem',
});

const CopyIcon = styled.img({

});

const Navigation = styled.div({
  display: 'flex',
  margin: '1.5rem 0',
  justifyContent: 'center',
});

const NavigationText = styled.p({
  width: '3.5rem',
  marginRight: '1rem',
  fontSize: '0.8rem',
  lineHeight: 1.5,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
});

const NavigationMethod = styled.a({
  display: 'block',
  backgroundColor: '#E1DAD3',
  borderRadius: '0.7rem',
  margin: '0 0.2rem',
  color: FontColor.Normal,
  fontWeight: 'bold',
  padding: '0.5rem 0.7rem',
  fontSize: '0.8rem',
  lineHeight: 1.2,
  '&:visited': {
    color: FontColor.Normal,
  },
});

const Parking = styled.div({
  color: FontColor.Normal,
});

const address = '서울 강남구 밤고개로 21길 79';

function WayByCar() {
  const [isSaved, setIsSaved] = useState(false);

  const onClickCopy = () => {
    navigator.clipboard.writeText(address);
    setIsSaved(true);
  };

  return (
    <Wrapper>
      <Address>
        {address}
        <Copy onClick={onClickCopy}><CopyIcon src={copyIcon} /></Copy>
      </Address>
      <Navigation>
        <NavigationText>길 안내 바로가기</NavigationText>
        <NavigationMethod href="https://map.kakao.com/link/to/빌라드지디수서,37.474134,127.115043">
          카카오
          <br />
          네비
        </NavigationMethod>
        <NavigationMethod href="tmap://route?goalx=127.115043&goaly=37.474134&goalname=빌라드지디 수서">티맵</NavigationMethod>
        {/* eslint-disable-next-line max-len */}
        <NavigationMethod href="https://map.naver.com/v5/directions/-/14150388.159608219,4505404.467324808,%EB%B9%8C%EB%9D%BC%EB%93%9C%EC%A7%80%EB%94%94%20%EC%88%98%EC%84%9C,1973278943,PLACE_POI/-/transit?c=15,0,0,0,dh">
          네이버
          <br />
          지도
        </NavigationMethod>
      </Navigation>
      <Parking>
        <SubTitle>- 주차 안내 -</SubTitle>
        <SubText>
          건물 옆 공터, 건너편 공영 주차장 이용 (약 180대)
          <br />
          주차 비용 - 2시간 무료, 10분 당 200원
        </SubText>
      </Parking>
      {
        isSaved
        && <Notification onDismiss={() => setIsSaved(false)} />
      }
    </Wrapper>
  );
}

export default WayByCar;

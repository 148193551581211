import styled from '@emotion/styled';
import { FontColor } from '~/constants';

export const SubTitle = styled.h3({
  margin: '1rem 0',
  fontWeight: 'bold',
  color: FontColor.Normal,
  textAlign: 'center',
  fontSize: '0.9rem',
});

export const SubText = styled.p({
  textAlign: 'center',
  fontSize: '0.8rem',
  lineHeight: 1.5,
  color: FontColor.Normal,
});

export const paddingTop = '3.2rem';
export const maxWidth = '35rem';

export enum FontColor {
  Normal = '#6A6A6A',
}

export enum Size {
  XSS = '@media only screen and (max-width: 320px)',
}

import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { keyframes } from '@emotion/react';

import flowerTopImg from '~/assets/flower_top.png';
import flowerBottomImg from '~/assets/flower_bottom.png';
import backgroundImg from '~/assets/background_door.jpg';
import weddingDayImg from '~/assets/wedding_day.png';
import Couple from './Couple';
import { paddingTop } from '~/constants';
import scrollIcon from '~/assets/scroll.png';

const Wrapper = styled.div({
  paddingTop,
  display: 'flex',
  height: '400vh',
});

const PhotoWrapper = styled.div({
  height: `calc(100vh - ${paddingTop})`,
  width: '100%',
  position: 'sticky',
  top: paddingTop,
  overflow: 'hidden',
  background: '#ABC9E6',
});

const Flower = styled.img({
  position: 'absolute',
  objectFit: 'cover',
  left: 0,
});

const FlowerTop = styled(Flower)<{ progress: number }>((props) => ({
  ...(props.progress > 75 && {
    top: `-${(props.progress - 75) / 3}rem`,
  }),
}));

const FlowerBottom = styled(Flower)<{ progress: number }>((props) => ({
  bottom: props.progress > 75 ? `-${(props.progress - 75) / 2}rem` : 0,
  ...(props.progress > 100 && {
    display: 'none',
  }),
}));

const Background = styled.img<{ progress: number }>((props) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  // width: '100%',
  height: '100%',
  ...(props.progress > 75 && {
    transform: `scale(${1 + (props.progress - 75) / 25}, ${1 + (props.progress - 75) / 25})`,
  }),
  objectFit: 'cover',
  opacity: props.progress > 75 ? 1 - ((props.progress - 75) / 30) : 1,
}));

const WeddingDay = styled.img<{ progress: number }>((props) => ({
  position: 'absolute',
  height: '55%',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 1 - (props.progress / 15),
  // bottom: '100%',
}));

const Bounce = keyframes`
  from, to {
    transform: translate(0, -1rem);
  }

  50% {
    transform: translate(0, 0);
  }
`;

const Scroll = styled.img<{ isHidden: boolean }>((props) => ({
  position: 'absolute',
  width: '3rem',
  bottom: '19vh',
  left: 'calc(50% - 1.5rem)',
  animation: `${Bounce} 2.5s ease-in-out infinite`,
  transition: 'opacity 1s ease-out',
  opacity: props.isHidden ? 0 : 1,
}));

function Intro() {
  const [progress, setProgress] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const ticking = useRef<boolean>(false);
  const [isScrollStart, setIsScrollStart] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (ref.current) {
            setProgress(Math.round((window.scrollY / (ref.current.offsetHeight - document.body.offsetHeight)) * 100));
            ticking.current = false;
          }
        });
      }
      ticking.current = true;
      if (!isScrollStart && progress > 5) {
        setIsScrollStart(true);
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isScrollStart, progress]);

  return (
    <Wrapper ref={ref} id="intro">
      <PhotoWrapper>
        <Background src={backgroundImg} progress={progress} />
        <FlowerTop src={flowerTopImg} progress={progress} />
        <FlowerBottom src={flowerBottomImg} progress={progress} />
        <WeddingDay src={weddingDayImg} progress={progress} />
        <Couple progress={progress} />
        <Scroll src={scrollIcon} isHidden={isScrollStart} />
      </PhotoWrapper>
    </Wrapper>
  );
}

export default Intro;
